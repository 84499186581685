export default function Section(props) {
    return (
        <div className="mb-5">
            <div className="mb-2">
                <h3 className="text-2xl">{props.title}</h3>
            </div>
            <>
                {props.children}
            </>
        </div>
    );
}