import Section from "./section";
import SectionItem from "./sectionItem";

export default function Privacy(props) {

    return (
        <div className="p-2">
            <div className="bg-indigo-900 px-8 py-10 rounded-t-lg text-white">
                <h1 className="text-3xl mb-2">Privacy Policy</h1>
                <span>Effective date: July 8, 2023</span>
            </div>

            <div className="p-8 bg-white rounded-b-lg text-gray-600">
                <Section>
                    <SectionItem>
                        <p>
                            This Privacy Policy ("Policy") describes how your personal information is collected, used, and disclosed when you use the "Somni" mobile application ("App"). By using the App, you consent to the data practices described in this Policy.
                        </p>
                    </SectionItem>
                </Section>
                <Section title="1. Information We Collect">
                    <SectionItem title="1. Account Information">
                        <p>
                            If you log in with your Apple ID we will receive your Apple ID and may receive the email address associated with your Apple ID, depending on your Apple settings.
                        </p>
                    </SectionItem>
                    <SectionItem title="2. Photos that you upload to Somni">
                        <p>
                            If you grant us permission to access your device’s photo library, we will process the photos you select to upload to Somni to provide features of the app that you choose to Use. For more information regarding how we process your photos, please see Section 3. How we process, share, and retain your photos and image data.
                        </p>
                    </SectionItem>
                    <SectionItem title="3. Usage Data">
                        <p>
                            We may also collect information about your device, including your IP address, device type, operating system version, and usage patterns within the App.
                        </p>
                    </SectionItem>
                </Section>
                <Section title="2. Use of Information">
                    <SectionItem>
                        <p>
                            We use the collected information to provide and improve the App's functionality and services.
                        </p>
                        <p>
                            Personal information may be used to authenticate your account, communicate with you, and provide customer support.
                        </p>
                        <p>
                            We may also use the information to analyze user behavior, monitor the effectiveness of the App, and personalize the user experience.
                        </p>
                        <p>
                            We may aggregate non-personal information for statistical purposes, but such data will not identify any individual user.
                        </p>
                    </SectionItem>
                </Section>
                <Section title="3. How we process, share, and retain your photos and image data">
                    <SectionItem>
                        <p>
                            We process your photos to provide you with the features of the Somni application that you choose to Use. In doing so, when you upload photos to Somni, we process your photos using technology that identifies data that is in the photos and provides us with information, such as information about your facial position, orientation and topology (“Face Data”), and other elements of your photo (collectively, “Image Data”). The following describes how we process, share, and retain your photos and Image Data in connection with various features of the Somni app. Please note, however, that we may retain your data for longer time periods than set forth below where required to do so by law.
                            <ul className="list-disc pl-8">
                                <li>AI Avatars: To generate AI Avatars, you must upload 10-20 photos to Somni and select a gender to be associated with the Avatar. The photos you upload are stored on our servers, which are hosted by Google Cloud Platform (USA). The AI Avatars feature relies on the Stable Diffusion model. We create a copy of the Stable Diffusion model, which is hosted on our GCP servers, and apply technology that retrains the model using the photos you uploaded and generates Image Data to create and personalize your Avatars. Immediately after the successful generation of Avatars, the copy of the Stable Diffusion model, as well as any associated Image Data, are deleted permanently from our GCP servers. The photos you upload are stored on our GCP servers until you deleted the related person. We do not Use your Personal Data to train and/or create separate artificial intelligence/products.</li>
                                <li>Generated Avatars: are stored on our servers (provided by Google Cloud Platform (USA)) and are available to you within the Somni app at any time, via any device from which you log into the Somni application, until you decide to either (i) delete the Person or, (ii) delete your Somni account. If you delete a Person, we will generally process the deletion immediately. If you delete your Somni account, we will delete your account, including the Avatars in your account, immediately. Please be aware that deleting the Somni application from your device does not result in the deletion of your Somni account!</li>
                            </ul>
                        </p>
                        <p>
                            If you wish to delete your personal information from our systems, please contact us at info@somni.maxantonov.tech
                        </p>
                    </SectionItem>
                </Section>
                <Section title="4. Third-Party Services and Sharing of Information">
                    <SectionItem>
                        <p>
                            We may disclose the information we process about you, including any Personal Data, as follows:
                            <ul className="list-disc pl-8">
                                <li>We may disclose your Personal Data, and other collected information to third-party organizations such as contractors, business partners, service providers, and vendors that we use to support our business and who assist us in providing Somni. Such service providers may include:
                                    <ul className="list-disc pl-8">
                                        <li>cloud provider Google Cloud Platform (USA), which we use to store your Personal Data, photos, and Image Data when you generate AI Avatars;</li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                        <p>
                            We may also disclose your personal information if required by law, regulation, legal process, or governmental request.
                        </p>
                        <p>
                            In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity.
                        </p>
                    </SectionItem>
                </Section>
                <Section title="5. Security">
                    <SectionItem>
                        <p>
                            We take reasonable precautions to protect your personal information from unauthorized access, loss, misuse, or alteration.
                        </p>
                        <p>
                            However, please be aware that no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee the absolute security of your information.
                        </p>
                    </SectionItem>
                </Section>
                <Section title="6. Children's Privacy">
                    <SectionItem>
                        <p>
                            The App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13 years of age. If we become aware that we have collected personal information from a child under 13 without parental consent, we will take steps to remove such information from our systems.
                        </p>
                    </SectionItem>
                </Section>
                <Section title="7. Updates to this Policy">
                    <SectionItem>
                        <p>
                            We reserve the right to update or modify this Policy at any time. The most current version will be posted in the App.
                        </p>
                        <p>
                            We encourage you to review this Policy periodically to stay informed about how we collect, use, and protect your personal information.
                        </p>
                    </SectionItem>
                </Section>
                <Section title="8. Contact Us">
                    <SectionItem>
                        <p>
                            If you have any questions, concerns, or requests regarding this Privacy Policy or the App, please contact us at info@somni.maxantonov.tech.
                        </p>
                    </SectionItem>
                </Section>

                By using the App, you acknowledge that you have read, understood, and agree to the practices and policies described in this Privacy Policy.
            </div>
        </div>
    );
}