export default function SectionItem(props) {
    return (
        <div className="mb-4 ml-4">
            <div className="mb-1">
                <h3 className="text-xl">{props.title}</h3>
            </div>
            <p>
                {props.children}
            </p>
        </div>
    );
}