import {Route, Routes} from "react-router-dom";
import Home from "./Components/home";
import Privacy from "./Components/privacy";
import Terms from "./Components/terms";

function App() {
  return (
      <div className="h-screen bg-gray-100">
          <div className="container mx-auto">
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="terms" element={<Terms />} />
                  <Route path="privacy" element={<Privacy />} />
              </Routes>
          </div>
      </div>

  );
}

export default App;
