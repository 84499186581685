import Section from "./section";
import SectionItem from "./sectionItem";

export default function Terms(props) {

    return (
        <div className="p-2">
            <div className="bg-indigo-900 px-8 py-10 rounded-t-lg text-white">
                <h1 className="text-3xl mb-2">Terms and Conditions</h1>
                <span>Effective date: July 8, 2023</span>
            </div>

            <div className="p-8 bg-white rounded-b-lg text-gray-600">
                <p className="mb-4">
                    These Terms of Use (these “Terms”) apply to all users and others (“Users”, “you”, as applicable) who download, install, register with, access or use our mobile application Somni (“Somni” or “application” or "App").
                </p>
                <p className="mb-4">
                    These Terms are a binding contract between you and us. You are accepting and agreeing to these Terms on your behalf or on behalf of the person or entity that you represent. If you are Using Somni on behalf of another person or entity, you represent that you are authorized to accept these Terms on that person’s or entity’s behalf and that such person or entity agrees to be responsible to us if you or the other person or entity violates these Terms.
                </p>
                <p className="mb-4">
                    If you do not agree to these Terms, do not use Somni. Your continued use of Somni will confirm your acceptance of these Terms.
                </p>
                <Section title="1. User Eligibility and Account">
                    <SectionItem>
                        <p>
                            Somni is not intended for or directed at children under 13, and we do not knowingly collect or solicit any information from anyone under the age of 13 or knowingly allow such persons to use Somni. If you are under 13, do not:

                            <ul className="list-disc pl-8">
                                <li>use or provide any information in Somni or through any of its features, or</li>
                                <li>provide any information about yourself to us, including your name, address, telephone number or email address.</li>
                            </ul>

                        </p>
                    </SectionItem>
                    <SectionItem title="Age limitation for EEA individuals">
                        <p>
                            You must be at least 18 years old in order to use Somni. We do not allow use of Somni by EEA individuals younger than 18 years old. If you are aware of anyone younger than 18 using Somni, please contact us (for contact information, please see Section 8: Miscellaneous), and we will take the required steps to delete the information provided by such persons.
                        </p>
                    </SectionItem>
                    <SectionItem title="Age limitation for UK individuals">
                        <p>
                            You must be at least 18 years old in order to use Somni. We do not allow use of Somni by UK individuals younger than 18 years old. If you are aware of anyone younger than 18 using Somni, please contact us (for contact information, please see Section 8: Miscellaneous), and we will take the required steps to delete the information provided by such persons.
                        </p>
                    </SectionItem>
                    <SectionItem>
                        <p>
                            Somni features or functionalities require you to register an account with us (“Account”). By creating an Account, you agree to:
                            <ul className="list-disc pl-8">
                                <li>provide accurate, current, and complete information for your Account</li>
                                <li>maintain and promptly update, as necessary, your Account information</li>
                                <li>maintain the security of your Account login information</li>
                                <li>be responsible for all activity that occurs via your account even if that activity is not by you or is without your knowledge or consent, and</li>
                                <li>immediately notify us if you discover or otherwise suspect any security breaches related to your Account</li>
                            </ul>
                        </p>

                        <p>
                            You are entirely responsible for maintaining the confidentiality, security  and control of your Account login information and for all activities that occur in your Account. We are not liable for any loss or damage arising from your failure to comply with the above requirements.
                        </p>
                        <p>
                            You may register your Account using a valid account on the app store or marketplace from which you downloaded Somni (e.g. Apple App Store, Google Play, etc.) (each such account, a “Third-Party Account”). By using Third-Party Account to register your Account, you are allowing Somni to access your Third-Party Account as permitted under the applicable terms and conditions that govern your and/our use of such Third-Party Account.
                        </p>
                        <p>
                            Please note that your relationship with the third-party service providers associated with your third-party accounts is governed solely by your agreement(s) with such third-party service providers.
                        </p>
                    </SectionItem>
                </Section>
                <Section title="2. Privacy">
                    <SectionItem>
                        <p>
                            Please refer to our Privacy Policy for information about how we process and share information about you when you use Somni. You acknowledge and agree that your use of Somni is subject to our Privacy Policy.
                        </p>
                    </SectionItem>
                </Section>
                <Section title="3. User Content">
                    <SectionItem>
                        <p>
                            Somni may allow you to upload, edit, create, store and share content, including photos, as well as generate and download your avatars (“User Content”). We do not claim ownership over your User Content.
                        </p>
                        <p>
                            For the purposes of operating or improving Somni, you grant us a perpetual, irrevocable, nonexclusive, royalty-free, worldwide, fully-paid, transferable, sub-licensable license to use, reproduce, modify, distribute, create derivative works of your User Content, without any additional compensation to you and always subject to your additional explicit consent for such use where required by applicable law and as stated in our Privacy Policy. The License is for the limited purpose of operating Somni or if otherwise implied by the using of Somni and its services unless you have provided us your additional explicit consent for the different purpose where required by applicable law. The License is time-limited in the sense that it automatically terminates when you delete the User Content from Somni or by terminating your account.
                        </p>
                        <p>
                            You acknowledge and agree that our use of your User Content will not result in any injury to you or to any person that you authorized to act on your behalf.
                        </p>
                        <p>
                            You represent and warrant that:
                            <ul className="list-decimal pl-8">
                                <li>you own the User Content edited and adjusted by you on or through Somni or otherwise have the right to grant the rights and licenses set forth in these Terms</li>
                                <li>you agree to pay for all royalties, fees, and any other monies owed by reason of the User Content you intend to edit and adjust on or through Somni and warrant to us that any usage of third-party User Content is cleared by you with the respective rightholder</li>
                                <li>if the User Content depicts third parties, you have received all the necessary consents form them to upload their photos to Somni, and</li>
                                <li>you have the legal right and capacity to enter into these Terms in your jurisdiction.</li>
                            </ul>
                        </p>
                        <p>
                            You must not upload, edit, create, store or share any User Content that violates these Terms or to which you do not have all the rights necessary to grant us the License described above. You, furthermore, agree to indemnify, defend and hold Somni harmless for any unauthorized use of third-party User Content you might commit (both intentionally or unintentionally) in accordance with Section 11 “Indemnification” of these Terms.
                        </p>
                        <p>
                            We will not be liable to you for any modification, suspension or discontinuation of Somni, or the loss of any User Content, if this loss occurs outside of our control (e.g., due to a security breach). However, we hereby commit to informing you of such security incident in accordance with our Privacy Policy (and its Section 8 “Data Security”).
                        </p>
                    </SectionItem>
                </Section>

                <Section title="4. Prohibited Conduct and Content">
                    <SectionItem>
                        <p>
                            You must not violate any applicable contract, intellectual property law, any other applicable law or other third-party rights (including the Company’s rights) or commit a tort, and you are solely responsible for your conduct while using Somni.
                        </p>
                        <p>
                            You represent, warrant and agree that you will not use Somni by uploading the User Content or otherwise (all of the following conduct includes any attempts to perform any of the following):
                            <ul className="list-disc pl-8">
                                <li>To engage in any harmful, fraudulent, deceptive, harassing, threatening, intimidating, predatory, defamatory, obscene, stalking or otherwise objectionable conduct.</li>
                                <li>In any manner that could interfere with, disrupt, negatively affect or inhibit other Users from fully enjoying Somni or that could damage, disable, overburden or impair the functioning of Somni in any manner.</li>
                                <li>To decipher, decompile, disassemble or reverse engineer any aspect of Somni (such as the source code or non-literal aspects (such as the underlying structure, sequence, organization, file formats, non-public APIs, ideas or algorithms), do anything that might discover the source code, bypass or circumvent measures employed to prevent or limit your access to any part of Somni.</li>
                                <li>To circumvent any content-filtering techniques we employ or to access any feature or area of Somni that you are not authorized to access.</li>
                                <li>To develop or to use any third-party applications that interact with Somni without our prior written consent, including any scripts designed to scrape or extract data from Somni.</li>
                                <li>To display, mirror or frame Somni, or any individual element within Somni, the Company’s name, any Company trademark, logo or other proprietary information without our express written consent.</li>
                                <li>To use any meta tags or other hidden text or metadata utilizing the Company’s trademark, logo URL, product or services name without our express written consent.</li>
                                <li>To gain unauthorized access to, interfere with, damage, or disrupt any server, computer or database connected to Somni.</li>
                                <li>To copy, modify, host, stream, sublicense, or resell Somni, or otherwise use Somni for any commercial purposes (this excludes selling your User Content).</li>
                                <li>For any illegal or unauthorized purpose, or engage in, encourage or promote any activity that violates these Terms.</li>
                                <li>To violate any applicable law or regulation.</li>
                                <li>To encourage, assist or enable any other party to do any of the foregoing.</li>
                            </ul>
                        </p>
                    </SectionItem>
                    <SectionItem title="Use of created Avatars">
                        <ul className="list-disc pl-8">
                            <li>Somni gives you the ability to use AI as a tool to create Avatars, and we claim no rights in the Avatars you generate through our AI.</li>
                            <li>You are the one to decide how to use the generated Avatars further, and you are the one accountable for their use. You must ensure that the way you are using your Avatars is not breaking any laws, such as privacy and intellectual property laws, and that such use is not obscene, pornographic, harmful and doesn’t spread harmful misinformation. If you are unsure that your intended use of the Avatars would be legal, we advise you to err on the side of caution, especially if using for commercial purposes.</li>
                        </ul>
                    </SectionItem>
                    <SectionItem>
                        <p>
                            Due to the fact that we use Stable Diffusion AI technology in Somni for creation of the Avatars, you additionally represent, warrant and agree that you will not Use Avatars feature in the following ways, which are explicitly prohibited under the Stable Diffusion AI technology license, and in particular:
                            <ul className="list-disc pl-8">
                                <li>In any way that violates any applicable national, federal, state, local or international law or regulation;</li>
                                <li>For the purpose of exploiting, harming or attempting to exploit or harm minors in any way;</li>
                                <li>To generate or disseminate verifiably false information and/or content with the purpose of harming others;</li>
                                <li>To generate or disseminate personal identifiable information that can be used to harm an individual;</li>
                                <li>To defame, disparage or otherwise harass others;</li>
                                <li>For fully automated decision making that adversely impacts an individual’s legal rights or otherwise creates or modifies a binding, enforceable obligation;</li>
                                <li>For any use intended to or which has the effect of discriminating against or harming individuals or groups based on online or offline social behavior or known or predicted personal or personality characteristics;</li>
                                <li>To exploit any of the vulnerabilities of a specific group of persons based on their age, social, physical or mental characteristics, in order to materially distort the behavior of a person pertaining to that group in a manner that causes or is likely to cause that person or another person physical or psychological harm;</li>
                                <li>For any use intended to or which has the effect of discriminating against individuals or groups based on legally protected characteristics or categories;</li>
                                <li>To provide medical advice and medical results interpretation;</li>
                                <li>To generate or disseminate information for the purpose to be used for administration of justice, law enforcement, immigration or asylum processes, such as predicting an individual will commit fraud/crime commitment (e.g., by text profiling, drawing causal relationships between assertions made in documents, indiscriminate and arbitrarily-targeted use).</li>
                            </ul>

                            You, furthermore, agree to abide by the Stable Diffusion AI technology license, incorporated herein by reference.
                        </p>
                        <p>
                            We reserve the right to:
                            <ul className="list-disc pl-8">
                                <li>Monitor your Use of Somni to ensure compliance with these Terms, and to comply with applicable law or other legal requirements</li>
                                <li>To investigate violations of these Terms or conduct that affects Somni</li>
                                <li>To consult and cooperate with law enforcement authorities to prosecute Users who violate the applicable law.</li>
                                <li>To, in our discretion and at any time, suspend or discontinue Somni, introduce new features or impose limitations on certain features, or restrict access to Somni.</li>
                            </ul>
                        </p>
                    </SectionItem>
                </Section>

                <Section title="5. Limitation of Liability">
                    <SectionItem>
                        <p>
                            To the fullest extent permitted by applicable law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or goodwill, arising from or relating to your use of Somni.
                        </p>
                        <p>
                            We do not guarantee the availability, reliability, or accuracy of the App, and you use it at your own risk.
                        </p>
                    </SectionItem>
                </Section>

                <Section title="6. Modifications">
                    <SectionItem>
                        <p>
                            We reserve the right to modify, update, or change this Agreement at any time, in our sole discretion. We will notify you of any material changes through Somni or by other means.
                        </p>
                        <p>
                            It is your responsibility to review this Agreement periodically to stay informed of any updates or changes. Your continued use of Somni after any modifications to this Agreement constitutes your acceptance of such modifications.
                        </p>
                    </SectionItem>
                </Section>
                <Section title="7. Governing Law and Dispute Resolution">
                    <SectionItem>
                        <p>
                            This Agreement shall be governed by and construed in accordance with the laws of Ireland.
                        </p>
                        <p>
                            Any dispute arising out of or relating to this Agreement shall be resolved through binding arbitration in accordance with the rules of Ireland.
                        </p>
                        <p>
                            Any arbitration shall take place in Ireland. The language of arbitration shall be English. The arbitrator's decision shall be final and binding.
                        </p>
                        <p>
                            Notwithstanding the above, either party may seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation, or violation of a party's intellectual property rights.
                        </p>
                    </SectionItem>
                </Section>
                <Section title="8. Miscellaneous">
                    <SectionItem>
                        <p>
                            This Agreement constitutes the entire agreement between you and us regarding the use of Somni and supersedes any prior or contemporaneous understandings and agreements.
                        </p>
                        <p>
                            If any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions shall continue to be valid and enforceable to the fullest extent permitted by law.
                        </p>
                        <p>
                            Our failure to enforce any right or provision of this Agreement shall not be deemed a waiver of such right or provision.
                        </p>
                        <p>
                            This Agreement is not assignable, transferable, or sublicensable by you except with our prior written consent.
                        </p>
                        <p>
                            The headings in this Agreement are for convenience only and shall not affect its interpretation.
                        </p>
                        <p>
                            Any notices or communications regarding this Agreement should be sent to the following address: info@somni.maxantonov.tech.
                        </p>
                    </SectionItem>
                </Section>

                <Section>
                    <SectionItem>
                        <p className="mt-8">
                            By using Somni, you acknowledge that you have read, understood, and agree to be bound by this Terms and Conditions Agreement.
                        </p>
                    </SectionItem>
                </Section>
            </div>
        </div>
    );
}